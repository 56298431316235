import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {
  "title": "医療体験を再デザインする - コーポレートサイトリニューアルへの想い",
  "date": "2016-08-18T02:00:00.000Z",
  "slug": "entry/2016/08/18/110000",
  "tags": ["medley"],
  "hero": "./2016_08_18.png",
  "heroAlt": "corp"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`はじめまして、メドレーのデザイナーの波切と申します。
今年の 7 月に入社してから取り組んでいたコーポレートサイトのリニューアルを公開しました。これをきっかけに、リニューアルの経緯とメドレーのデザインのこれからについて少しお話しさせていただければと思います。`}</p>
    <p><strong parentName="p">{`コーポレートサイト：`}<a parentName="strong" {...{
          "href": "https://www.medley.jp/"
        }}>{`https://www.medley.jp/`}</a></strong></p>
    <h1>{`医療体験を再デザインする`}</h1>
    <p>{`メドレーはインターネットを通じて医療ヘルスケア分野の課題を解決することを目指す会社です。`}</p>
    <p>{`・医師がつくるオンライン病気辞典「`}<a parentName="p" {...{
        "href": "https://medley.life/"
      }}>{`MEDLEY`}</a>{`」
・遠隔診療ソリューション「`}<a parentName="p" {...{
        "href": "https://clinics.medley.life/"
      }}>{`CLINICS`}</a>{`」
・日本最大級の医療介護求人サイト「`}<a parentName="p" {...{
        "href": "https://job-medley.com/"
      }}>{`ジョブメドレー`}</a>{`」
・口コミで探せる介護施設の検索サービス「`}<a parentName="p" {...{
        "href": "https://www.kaigonohonne.com/"
      }}>{`介護のほんね`}</a>{`」`}</p>
    <p>{`現在は上記の 4 つのサービスがそれぞれに医療と介護にまつわる課題解決に向けて運営を行っており、メドレーのデザインの根幹も会社のミッション同様、医療ヘルスケア分野の課題を解決し、医療体験の再デザインを行っていくことにあります。`}</p>
    <h1>{`コーポレートサイトのリニューアルはデザインに対する意思表明`}</h1>
    <p>{`これまでのメドレーは専任デザイナーが不在だったため、サービスごとに様々なデザイン上の課題を抱えていました。そんななかで、入社して真っ先に取り組んだプロジェクトはコーポレートサイトのリニューアルでした。`}</p>
    <p>{`これは、自身が入社して感じた社内の熱気と充実したサポートチームの存在から、「サービスを利用してもらうユーザーや医療施設の方に対してはもちろん、メドレーに興味を持っている方にもどのような人間がサービスを作り運営しているかを知ってもらいたい」と感じたことがきっかけとなっています。
さらに、開発をリードする CTO の平山はデザインへの理解も深く、デザイン体制の構築が始まったタイミングで「メドレーは事業面だけでなくデザイン面でも業界をリードしていく」という意思表示を込め、まずは会社の顔であるコーポレートサイトのリニューアルに取り組もうということになりました。`}</p>
    <p>{`その結果として`}<a parentName="p" {...{
        "href": "https://takehirogoto.com/"
      }}>{`後藤武浩`}</a>{`さんに人と社内の空気を写真と動画に収めてもらい、メドレーが持つ熱気のある力強さを掛け合わせたデザインに仕上げました。
リリースしてから間もないのでまだ評判は聞けていませんが、奇をてらわず、社内の雰囲気が見えデザインへの意識も伝えられるサイトが出来たのではないかと思っています。`}</p>
    <p><img parentName="p" {...{
        "src": "https://cdn-ak.f.st-hatena.com/images/fotolife/m/medley_inc/20160816/20160816203803.jpg",
        "alt": "f:id:medley_inc:20160816203803j:plain",
        "title": "f:id:medley_inc:20160816203803j:plain"
      }}></img></p>
    <h1>{`ユーザーファーストとパブリックマインド、大きいデザインと小さいデザイン`}</h1>
    <p>{`メドレーを「医療ヘルスケア分野のスタートアップ」として捉えていらっしゃるかもしれません。しかしメドレーは、その枠組みでは伝わらない大きな夢を持った会社です。`}<a parentName="p" {...{
        "href": "https://d.hatena.ne.jp/keyword/%C2%E5%C9%BD%BC%E8%C4%F9%CC%F2"
      }}>{`代表取締役`}</a>{`医師の豊田をはじめ社内には現在 7 名の医師がおり、「納得できる医療が実現できる社会づくりに貢献する」という想いを持って事業へ取り組んでいます。`}</p>
    <p>{`先にお伝えしたようにメドレーは医療体験そのものをデザインし直そうとしており、UI/UX、HCD という「ユーザーが喜ぶか」という視点だけでなく、事業会社としての数値達成への視点ももちろん、「医療領域の課題解決につながるか」という社会的意義の強い視点と、多くの視点を持ってデザインに取り組んでいく必要があると感じています。`}</p>
    <p>{`社会的意義が強く大きな夢を描くほど、多くの人を惹きつけるヴィジョンを見せ、実際のプロダクトも作り上げなければいけない必要があり、それらの取り組みをしていく上で「大きな視点への理解」と、人を惹きつけるための「細かい配慮と魅力あるアート（個でもあり美術でもある）」の両立がデザインの鍵と感じています。
デザインに求められることも多いですが、エンジニアとプランナーと協力しながら多くの人を幸せにできるサービスを作っていきたいと思っています。`}</p>
    <h1>{`ブレのない一つのストーリーを`}</h1>
    <p>{`デザイナーの大事な仕事としてサービスのデザインの他にもメドレーという会社と各サービスのブランド構築があります。`}</p>
    <p>{`今回のコーポレートサイトもその一環で、ロゴのデザインやレギュレーション設計といった見た目の機能的な側面はもちろん、会社と各サービスの関係性を整理したサービス`}<a parentName="p" {...{
        "href": "https://d.hatena.ne.jp/keyword/%A5%DD%A1%BC%A5%C8%A5%D5%A5%A9%A5%EA%A5%AA"
      }}>{`ポートフォリオ`}</a>{`の管理、イベントや映像など様々な表現に寄与できるブランドコンセプトの管理をしていきたいと考えています。`}</p>
    <p>{`幸いメドレーは創業から今までと各サービスの関係性には「医療ヘルスケア分野の課題を解決する」というブレのない一つのストーリーに集束出来ており、シンプルで強いブランド構築が出来ると感じています。
ブランド構築の手法も従来の「知ってもらうためにブランドイメージをばらまく」ようなものではなく、スタートアップらしく事業ドリブンでメディアやサービスサイトなどメドレーとの接点となる体験の質を上げて、良いブランドイメージを積み上げていくような方法をと考えています。`}</p>
    <h1>{`メドレーは堂々と王道を歩む`}</h1>
    <p>{`MEDLEY では医療が必要な人・関わる人に対して最新かつ正しい情報を提供できるよう、350 人を超える医師が匿名で多角的な検討とチェックを行う体制を築くことで、その品質を向上させています。CLINICS では領域自体がこれから作られていく遠隔診療について、法的観点の見解を明確にし、医療機関と綿密にコミュニケーションを取るなど、適切な普及に向けて貢献しています。`}</p>
    <p>{`ジョブメドレーでは医療現場の人材不足解決のために、離職中の資格保有者への復職や、都心に比べ情報へのアクセスが難しい地方求人の取り扱いにも注力するほか、求職者および医療施設の双方にむけたサポート体制を充実させています。介護のほんねにおいても急な施設入居が必要になった方・十分に施設を検討したい方のために相談員による電話サポートを行っています。`}</p>
    <p>{`会社としても、それぞれの事業で真摯に課題とユーザーに向き合うことができるよう、社員がやりたい・やるべきことをスピード感をもって取り組むための体制づくりを目指しています。`}</p>
    <p>{`事業がどのような社会貢献を果たしているかということは、ブランドとデザインを考える上で最も重要なことです。メドレーは、ユーザーはもちろん医療領域・社会全体へ貢献するためにあらゆるサービスを提供しており、余計な手を加える必要もなく今ある姿をシンプルにデザインへ落としこめる状態にあります。`}</p>
    <p>{`医療領域においても、デザインにおいても、メドレーは堂々と王道を歩むことが出来る会社であり、そのデザインを出来ること・関われることはデザイナーとしても理想的な環境だと思っています。`}</p>
    <h1>{`未完成を楽しめるデザイナー`}</h1>
    <p>{`メドレーのデザインとこれからやっていきたいことについてお話しさせていただきましたが、デザイナーはとにかく人手が足りておらず、メドレーが描く大きな夢をブランドやサービスとして、抽象と具体を行き来して設計に落とし込めるデザイナーを募集しています。`}</p>
    <p>{`※`}<a parentName="p" {...{
        "href": "https://www.medley.jp/recruit/creative.html"
      }}>{`募集概要はこちら`}</a></p>
    <p>{`会社や事業としてポテンシャルが高くその部分では良い仕組みが出来てきているのに、それに続くデザイナーの体制がまだまだ構築出来ていません。裏を返せばこれだけ可能性を秘めている会社のデザイン体制を 0 から構築出来る機会なんてそうそう出会えないと思う（自身がそう思って転職した）ので、未完成であることに魅力を感じ共感していただける人であればとにかく一度オフィスへ遊びに来ていただきたいです。`}</p>
    <h1>{`まだまだ始まったばかり`}</h1>
    <p>{`今日お話しした内容も、まだまだ「こうしたい」の域であってこれからどんどん実行していかなければいけません。
メドレーには経験豊富なエンジニアチームと理解のあるディレクター・プランナーしかいない、とてもデザインに集中出来る環境が揃っているので、これから成果が出るまで愚直にやり続けていきたいと思います。後々にはデザイナーコミュニティにも貢献していければと思っていますので、ぜひこれからもメドレーの動向を気にかけていただけますと幸いです。（そしてデザイナーの方はぜひ一度遊びに来てください！）`}</p>
    <p><img parentName="p" {...{
        "src": "https://cdn-ak.f.st-hatena.com/images/fotolife/m/medley_inc/20160816/20160816203808.jpg",
        "alt": "f:id:medley_inc:20160816203808j:plain",
        "title": "f:id:medley_inc:20160816203808j:plain"
      }}></img></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      